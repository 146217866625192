import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"

class Footer extends React.Component {
  render() {
    const FooterDiv = styled.footer`
      position: relative;
      bottom: 0;
      width: 100%;
      left: 0;
      background-color: DarkCyan;
      color: Black;
      font-family: "Open Sans", sans-serif;
      text-align: center;
    `

    return (
      <FooterDiv>
        <div>Email: sample.email@sample.com</div>
        <div>Phone: (123) 456-7890</div>
        <div> Problem with the site? Let us know: (Henry's number here)</div>
        <div style={{ fontSize: "smaller", color: "grey" }}>
          {new Date().getFullYear()}, Prototype Built by
          {` `}
          Henry Haner
        </div>
      </FooterDiv>
    )
  }
}

export default Footer
