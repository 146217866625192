import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../components/logo"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"

const HeaderWrapper = styled.header`
  background: DarkCyan;
  margin-bottom: 1rem;
  height: 55px;
  width: 100%;
  position: fixed;
  zindex: 5;
`

const NavBarWrapper = styled.div`
  padding-bottom: 2.5rem;
  padding-right: 2rem;
  padding-left: 5rem;
`

const MenuWrapper = styled.ul`
  display: flex;
  flex: 1;
  float: left;
`

const HomeItem = styled.li`
  position: absolute;
  display: inline-block;
  color: Black;
  text-align: center;
  padding: 14px 20px;
  margin: 0px;
  text-decoration: none;
  list-style-type: none;
  font-family: Open Sans, Varela Round, Geneva, sans-serif;
  font-style: bold;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  &:hover {
    transform: scale(1.5);
  }
`

const MenuItem = styled.li`
  display: inline-block;
  color: Black;
  text-align: center;
  padding: 14px 16px;
  margin: 0px;
  text-decoration: none;
  list-style-type: none;
  font-family: Open Sans, Varela Round, Geneva, sans-serif;
  font-style: bold;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  &:hover {
    background-color: Grey;
  }
`

const linkStyles = {
  linkInactiveStyle: {
    color: `Black`,
    textDecoration: "none",
    padding: "1em",
    fontFamily: "Nanum Gothic, sans-serif",
  },
  linkActiveStyle: {
    color: "White",
    textShadow: "1px 1px Grey",
    fontFamily: "Nanum Gothic, sans-serif",
  },
}

class Header extends React.Component {
  render() {
    const { siteTitle, menuLinks } = this.props

    return (
      <HeaderWrapper>
        <HomeItem>
          <Link to="/">
            {" "}
            <Logo />
          </Link>
        </HomeItem>
        <NavBarWrapper>
          <nav>
            <MenuWrapper>
              {menuLinks.map(link => (
                <MenuItem key={link.name}>
                  <Link
                    style={linkStyles.linkInactiveStyle}
                    to={link.link}
                    activeStyle={linkStyles.linkActiveStyle}
                  >
                    {link.name}
                  </Link>
                </MenuItem>
              ))}
            </MenuWrapper>
          </nav>
        </NavBarWrapper>
      </HeaderWrapper>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
